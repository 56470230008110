import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-button',
  templateUrl: './signup-button.component.html',
  styleUrl: './signup-button.component.scss'
})
export class SignupButtonComponent {
  public constructor(private router: Router) {}
  public async goToSignup(): Promise<void> {
    await this.router.navigate(['/registrace']);
  }
}
