import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppStateService {
  public isAppLoading = new BehaviorSubject(<undefined | boolean>undefined);

  public get userUid(): string {
    return <string>localStorage.getItem('userUid');
  }
}
