import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-article-content',
  templateUrl: './article-content.component.html',
  styleUrl: './article-content.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ArticleContentComponent {
  @Input({ required: true }) public text: string;
}
