<mat-toolbar class="header">
  <mat-toolbar-row class="pl-0">
    <div>
      <a routerLink="/">
        <img
          class="logo"
          alt="Logo obrázek"
          [src]="
            device === 'desktop'
              ? '/assets/logo.png'
              : '/assets/logo-small.png '
          "
          height="40"
        />
      </a>
    </div>

    <div class="ml-2 hidden">
      <strong>{{ title }}</strong>
      <p>{{ subtitle }}</p>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
