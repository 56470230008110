import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloseButtonComponent } from '@shared/components/close-button/close-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrl: './dialog-title.component.scss',
  standalone: true,
  imports: [CommonModule, CloseButtonComponent, FontAwesomeModule],
  encapsulation: ViewEncapsulation.None
})
export class DialogTitleComponent {
  @Input({ required: true }) public title: string;
  @Input({ required: true }) public icon: IconProp;
  @Input() public disableCloseButton = false;
}
