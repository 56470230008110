export const environment = {
  gaCode: '',
  hmr: false,
  firebase: {
    apiKey: 'AIzaSyCozxOtpqriefolWm8nyMOTK0oW6F0cWBg',
    authDomain: 'odchod---club---test.firebaseapp.com',
    databaseURL:
      'https://odchod---club---test-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'odchod---club---test',
    storageBucket: 'odchod---club---test.appspot.com',
    messagingSenderId: '292765941157',
    appId: '1:292765941157:web:9ce545f65f47c4a150fc4c'
  },
  production: false,
  baseUrl: 'https://test.zobrazeno.com',
  apiUrl: 'https://europe-west3-odchod---club---test.cloudfunctions.net/app',
  stripePublic:
    'pk_test_51HuN6fJ45zQA9yNWj7nl8JgFN5gIdK1j4pZ56MqunSayAtzEm0KzwHvwvk72zfj1qqr6ilghFCjqblYJnzt2qD0B00zHC0Mvo1',
  stripeSubscriptionPrices: [
    {
      amount: 1,
      id: 'price_1NkAF1J45zQA9yNWuhpWqpOf'
    },
    {
      amount: 135,
      id: 'price_1NkACGJ45zQA9yNW36tAEBdc'
    },
    {
      amount: 185,
      id: 'price_1NkACGJ45zQA9yNWBKK1pwy3'
    },
    {
      amount: 235,
      id: 'price_1NkACGJ45zQA9yNWApMcZYPZ'
    },
    {
      amount: 335,
      id: 'price_1NkACGJ45zQA9yNWWAhKk5dX'
    }
  ],
  stripeDonationPrices: [
    {
      amount: 100,
      id: 'price_1OJlgBJ45zQA9yNWTLpBtrrG'
    },
    {
      amount: 250,
      id: 'price_1OJlh8J45zQA9yNWKINTRKNg'
    },
    {
      amount: 500,
      id: 'price_1OJlhMJ45zQA9yNWLTgNOE2T'
    },
    {
      amount: 1000,
      id: 'price_1OJli9J45zQA9yNW32XwJZzl'
    }
  ]
};

import 'zone.js/plugins/zone-error';
