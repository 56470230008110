import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarNotificationService } from '@core/snack-bar-notification/snack-bar-notification.service';
import { DonationApiService } from '@core/api/donation-api.service';
import {
  faCoffee,
  faDollarSign,
  faUniversity,
  faComments,
  faFlag
} from '@fortawesome/free-solid-svg-icons';

interface MakeTransferDonationDialogData {
  authorUid: string;
  price: number;
}

@Component({
  selector: 'app-shared-make-transfer-donation-dialog',
  templateUrl: './make-transfer-donation-dialog.component.html',
  styleUrls: ['./make-transfer-donation-dialog.component.scss']
})
export class MakeTransferDonationDialogComponent {
  public faCoffee = faCoffee;
  public faDollarSign = faDollarSign;
  public faUniversity = faUniversity;
  public faComments = faComments;
  public faFlag = faFlag;
  public loading = false;
  public referenceNumber = Math.floor(
    Math.pow(10, 7 - 1) + Math.random() * 9 * Math.pow(10, 7 - 1)
  );

  public constructor(
    public dialogRef: MatDialogRef<MakeTransferDonationDialogComponent>,
    private notificationService: SnackBarNotificationService,
    private donationApiService: DonationApiService,
    @Inject(MAT_DIALOG_DATA) public data: MakeTransferDonationDialogData
  ) {}

  public async onCheckPayment(): Promise<void> {
    this.loading = true;

    try {
      const res = await this.donationApiService.checkDonationTransfer(
        this.referenceNumber,
        this.data.price,
        this.data.authorUid
      );
      if (res.status) {
        this.notificationService.showSuccess(
          'Váš dar dorazil',
          `Částka ${this.data.price} Kč byla přijata. Děkujeme!`
        );

        this.dialogRef.close();
      } else {
        this.notificationService.showError(
          'Chyba',
          'Platba nenalezena, zkuste to později'
        );
      }
    } catch (e) {
      this.notificationService.showError(
        'Chyba',
        'Kontrola platby selhala, zkuste to později'
      );
    } finally {
      this.loading = false;
    }
  }
}
