import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { User } from '@appTypes/auth.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountApiService {
  public account$: BehaviorSubject<User>;
  public constructor(private backendService: BackendService) {}

  public async getAccount(): Promise<User> {
    const resp = await this.backendService.get<User>(
      `/account/${this.backendService.userUid}`
    );

    if (resp.price) {
      resp.price = Number(resp.price);
    }

    if (!this.account$) {
      this.account$ = new BehaviorSubject<User>(resp);
    } else {
      this.account$.next(resp);
    }
    return resp;
  }

  public updateAccount(values: User): Promise<void> {
    return this.backendService.put<void>(
      `/account/${this.backendService.userUid}`,
      values
    );
  }

  public deleteAccount(): Promise<void> {
    return this.backendService.delete<void>(
      `/account/${this.backendService.userUid}`
    );
  }
}
