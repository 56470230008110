<footer class="bg-white">
  <div class="layout-wrapper py-2 footer">
    <div class="flex items-center flex-col lg:flex-row">
      <a [routerLink]="['/']" class="footer-logo">
        <div>
          <img
            class="logo"
            alt="Logo obrázek"
            src="/assets/logo.png"
            height="50"
          />
        </div>
      </a>

      <span class="spacer"></span>

      <div class="links">
        <a [routerLink]="['/domu']"> Domů </a>
        <a
          href="https://pravda-vitezi.cz/?utm_source=zobrazeno&utm_medium=direct&utm_campaign=footer"
          target="_blank"
        >
          E-shop
        </a>
        <!--      <a [routerLink]="['/nas-tym']"> Náš tým </a>-->
        <a (click)="openContactDialog()"> Kontakt </a>

        <ng-container *ngIf="device != 'desktop'">
          <a [routerLink]="['/prihlaseni']"> Přihlásit </a>
          <a (click)="goToSignup()"> Registrovat se </a>
        </ng-container>
      </div>

      <div class="action-buttons">
        <a mat-flat-button color="primary" [routerLink]="['/prihlaseni']">
          Přihlásit
        </a>
        <a mat-flat-button color="accent" (click)="goToSignup()">
          Registrovat se
        </a>
      </div>
    </div>

    <mat-divider />

    <div class="flex justify-between flex-col lg:flex-row">
      <span>© 2024 Odchod z.s. Halasova 157/20, Vítkovice, 703 00 Ostrava</span>
      <a *ngIf="device === 'desktop'" (click)="openContactDialog()"
        >podpora&#64;zobrazeno.com</a
      >
    </div>
  </div>
</footer>
