<div class="avatar-container">
  <div
    class="image-container"
    [class.no-avatar]="!isAvatarMode"
    *ngIf="file; else placeholder"
    [ngStyle]="{ backgroundImage: 'url(' + file + ')' }"
  ></div>

  <ng-template #placeholder>
    <div
      class="image-container avatar-placeholder"
      [class.no-avatar]="!isAvatarMode"
      [ngStyle]="{ backgroundImage: 'url(' + placeholderImage + ')' }"
    ></div>
  </ng-template>

  <label for="cropper-tool-input-file-icon" *ngIf="isAvatarMode">
    <div class="icon-wrapper">
      <input
        class="hidden"
        type="file"
        id="cropper-tool-input-file-icon"
        accept="image/*"
        (change)="onFileChange($event)"
      />
      <fa-icon [icon]="faCameraRetro" class="icon"></fa-icon>
    </div>
  </label>

  <label for="cropper-tool-input-file-button" *ngIf="!isAvatarMode">
    <div class="upload-button-wrapper">
      <input
        #inputFileButton
        class="hidden"
        type="file"
        id="cropper-tool-input-file-button"
        accept="image/*"
        (change)="onFileChange($event)"
      />

      <button
        type="button"
        (click)="inputFileButton.click()"
        mat-flat-button
        color="primary"
      >
        Nahrát náhledový obrázek
      </button>
    </div>
  </label>
</div>
