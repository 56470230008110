<div class="notification" [ngClass]="type">
  <div class="notification__content">
    <ng-container *ngIf="type === 'success'">
      <fa-icon class="type" [icon]="faCheck" />
    </ng-container>
    <ng-container *ngIf="type === 'error'">
      <fa-icon class="type" [icon]="faXmark" />
    </ng-container>
    <ng-container *ngIf="type === 'info'">
      <fa-icon class="type" [icon]="faBell" />
    </ng-container>
    <h1 [innerHTML]="data.title" class="text-lg"></h1>
    <p [innerHTML]="data.description"></p>
    <fa-icon class="close" (click)="close()" [icon]="faXmark" />
  </div>
</div>
