import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared-profile-image',
  templateUrl: './user-profile-image.component.html',
  styleUrls: ['./user-profile-image.component.scss']
})
export class UserProfileImageComponent {
  @Input() public width = '50px';
  @Input() public height = '50px';
  @Input() public profileImageUrl: string;
}
