<app-dialog-title title="Darování" [icon]="faCoffee" />

<form [formGroup]="paymentForm" (ngSubmit)="onPayment()">
  <div mat-dialog-content>
    <div>
      <div class="flex justify-between">
        <p class="inline font-semibold">Kolik si přejete darovat autorovi?</p>
      </div>

      <mat-button-toggle-group [formControl]="priceFormControl" class="w-full">
        <mat-button-toggle class="w-full" [value]="100"
          >100 Kč</mat-button-toggle
        >
        <mat-button-toggle class="w-full" [value]="250"
          >250 Kč</mat-button-toggle
        >
        <mat-button-toggle class="w-full" [value]="500"
          >500 Kč</mat-button-toggle
        >
        <mat-button-toggle class="w-full" [value]="1000"
          >1000 Kč</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
  </div>

  <div mat-dialog-actions>
    <button
      cdkFocusInitial
      [disabled]="loading || !paymentForm.valid || !stripe"
      class="w-full"
      mat-flat-button
      type="submit"
      color="accent"
    >
      <mat-icon *ngIf="loading">
        <mat-spinner />
      </mat-icon>
      Darovat
    </button>
  </div>
</form>
