import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { TextInputComponent } from '@shared/ui/text-input/text-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { ContentDividerComponent } from '@shared/ui/content-divider/content-divider.component';
import { PublicHeaderComponent } from '@shared/public-header/public-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ImageCropperDialogComponent } from '@shared/components/cropper-tool/image-cropper-dialog/image-cropper-dialog.component';
import { CropperToolComponent } from '@shared/components/cropper-tool/cropper-tool.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UserProfileImageComponent } from './components/user-profile-image/user-profile-image.component';
import { PublishDateComponent } from '@shared/components/article/publish-date/publish-date.component';
import { ArticleInfoComponent } from '@shared/components/article/article-info/article-info.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CountLikesPipe } from '../admin/dashboard/feed/pipes/count-likes.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CloseButtonComponent } from '@shared/components/close-button/close-button.component';
import { ArticleContentComponent } from '@shared/components/article/article-content/article-content.component';
import { SanitizeHtmlPipe } from '@shared/pipes/sanitize.pipe';
import { ArticleActionsComponent } from '@shared/components/article/article-actions/article-actions.component';
import { MakeDonationDialogComponent } from '@shared/dialogs/make-donation-dialog/make-donation-dialog.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MakeTransferDonationDialogComponent } from '@shared/dialogs/make-transfer-donation-dialog/make-transfer-donation-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { PromoVideoComponent } from '@shared/components/promo-video/promo-video.component';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { FreeBadgeComponent } from '@shared/components/free-badge/free-badge.component';
import { PremiumBadgeComponent } from '@shared/components/premium-badge/premium-badge.component';
import { MatDividerModule } from '@angular/material/divider';
import { SignupButtonComponent } from '@shared/components/signup-button/signup-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    RouterLink,
    MatToolbarModule,
    MatDialogModule,
    MatBadgeModule,
    CountLikesPipe,
    MatTooltipModule,
    CloseButtonComponent,
    SanitizeHtmlPipe,
    DialogTitleComponent,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    QRCodeModule,
    MatDividerModule,
    MatMenuModule,
    FontAwesomeModule
  ],
  declarations: [
    TruncatePipe,
    TextInputComponent,
    ContentDividerComponent,
    PublicHeaderComponent,
    ImageCropperDialogComponent,
    CropperToolComponent,
    UserProfileImageComponent,
    PublishDateComponent,
    ArticleInfoComponent,
    ArticleContentComponent,
    ArticleActionsComponent,
    MakeDonationDialogComponent,
    MakeTransferDonationDialogComponent,
    PromoVideoComponent,
    FooterComponent,
    FreeBadgeComponent,
    PremiumBadgeComponent,
    SignupButtonComponent
  ],
  exports: [
    TruncatePipe,
    TextInputComponent,
    ContentDividerComponent,
    PublicHeaderComponent,
    ImageCropperDialogComponent,
    CropperToolComponent,
    UserProfileImageComponent,
    PublishDateComponent,
    ArticleInfoComponent,
    ArticleContentComponent,
    ArticleActionsComponent,
    MakeDonationDialogComponent,
    PromoVideoComponent,
    FooterComponent,
    FreeBadgeComponent,
    PremiumBadgeComponent,
    SignupButtonComponent
  ],
  providers: []
})
export class SharedModule {}
