import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountApiService } from '@core/api/account-api.service';
import { User } from '@appTypes/auth.interface';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';
import { MakeTransferDonationDialogComponent } from '@shared/dialogs/make-transfer-donation-dialog/make-transfer-donation-dialog.component';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

interface MakeDonationDialogData {
  authorUid: string;
}

@Component({
  selector: 'app-shared-make-donation-dialog',
  templateUrl: './make-donation-dialog.component.html',
  styleUrls: ['./make-donation-dialog.component.scss']
})
export class MakeDonationDialogComponent implements OnInit {
  public stripe: Stripe | null;
  public loading = false;
  public paymentForm: UntypedFormGroup;
  private account: User;
  public priceFormControl = new UntypedFormControl(100);
  public constructor(
    public dialogRef: MatDialogRef<MakeDonationDialogComponent>,
    public dialog: MatDialog,
    private accountApiService: AccountApiService,
    @Inject(MAT_DIALOG_DATA) private data: MakeDonationDialogData
  ) {
    this.paymentForm = new UntypedFormGroup({
      price: this.priceFormControl
    });

    this.accountApiService.account$.subscribe((user) => {
      this.account = user;
    });

    this.priceFormControl.valueChanges.subscribe((val) => {
      this.priceFormControl.patchValue(Number(val), { emitEvent: false });
    });
  }

  public async ngOnInit(): Promise<void> {
    this.stripe = await loadStripe(environment.stripePublic);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public async onPayment(): Promise<void> {
    if (this.account.paymentType === 'CARD') {
      const callbackUrl = `${environment.baseUrl}/admin/dashboard?donation_session_id={CHECKOUT_SESSION_ID}&price=${this.priceFormControl.value}&toUserUid=${this.data.authorUid}`;
      await this.stripe?.redirectToCheckout({
        customerEmail: this.account.email,
        locale: 'cs',
        submitType: 'donate',
        successUrl: callbackUrl,
        cancelUrl: callbackUrl,
        lineItems: [
          {
            price: environment.stripeDonationPrices.find(
              (item) => item.amount == this.priceFormControl.value
            )?.id,
            quantity: 1
          }
        ],
        mode: 'payment'
      });
    } else {
      this.dialog.open(MakeTransferDonationDialogComponent, {
        data: {
          authorUid: this.data.authorUid,
          price: this.priceFormControl.value
        }
      });
      this.closeDialog();
    }
  }

  public faCoffee = faCoffee;
}
