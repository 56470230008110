import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { LikeRequest } from '@appTypes/like.interface';

@Injectable({ providedIn: 'root' })
export class LikeApiService {
  public constructor(private backendService: BackendService) {}

  public async makeLike(body: LikeRequest): Promise<void> {
    return await this.backendService.post<void>(
      `/like/${this.backendService.userUid}`,
      body
    );
  }
}
