import { Component, Injector } from '@angular/core';
import { AppBaseComponent } from '../../../app-base.component';
import { MakeContactComponent } from '../../../dialogs/make-contact/make-contact.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent extends AppBaseComponent {
  public constructor(private injector: Injector, private dialog: MatDialog) {
    super(injector);
  }

  public async goToSignup(): Promise<void> {
    await this.router.navigate(['/registrace']);
  }

  public openContactDialog(): void {
    this.dialog.open(MakeContactComponent, {
      width: '400px'
    });
  }
}
