import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { filter, map } from 'rxjs/operators';

export type DeviceName = 'phone' | 'tablet' | 'desktop';

const PHONE_BREAKPOINTS = ['(max-width: 600px)'];
const TABLET_BREAKPOINTS = ['(min-width: 601px) and (max-width: 992px)'];
const DESKTOP_BREAKPOINTS = ['(min-width: 992px)'];

@Injectable({ providedIn: 'root' })
export class BreakpointsService {
  public device$!: Observable<DeviceName>;

  public constructor(private breakpointObserver: BreakpointObserver) {
    this.initBreakpoints();
  }

  private initBreakpoints(): void {
    const provideObservable = (
      breakPoint: string[],
      deviceName: DeviceName
    ): Observable<DeviceName> => {
      return this.breakpointObserver.observe(breakPoint).pipe(
        filter((state) => state.matches),
        map(() => deviceName)
      );
    };

    this.device$ = merge(
      provideObservable(PHONE_BREAKPOINTS, 'phone'),
      provideObservable(TABLET_BREAKPOINTS, 'tablet'),
      provideObservable(DESKTOP_BREAKPOINTS, 'desktop')
    );
  }
}
