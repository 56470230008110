<div
  mat-dialog-content
  class="p-0 overflow-hidden image-cropper-wrapper"
  [class.avatar-mode]="data.parent.isAvatarMode"
>
  <img id="image" height="100" [src]="sanitizedUrl" />
</div>

<div mat-dialog-actions class="flex justify-between">
  <button class="mr-2" mat-dialog-close mat-flat-button>Zrušit</button>
  <button color="primary" mat-flat-button (click)="crop()">Nahrát</button>
</div>
