import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';
import { ContactRequest } from '@appTypes/contact.interface';

@Injectable({ providedIn: 'root' })
export class ContactApiService {
  public constructor(private backendService: BackendService) {}

  public sendMessage(body: ContactRequest): Promise<void> {
    return this.backendService.post<void>(`/contact`, body);
  }
}
