import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@core/authentication/auth.service';
import { FirebaseError } from 'firebase/app';
import { SnackBarNotificationService } from '@core/snack-bar-notification/snack-bar-notification.service';
import { MatDialogRef } from '@angular/material/dialog';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dialogs-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent {
  public resetPasswordForm: UntypedFormGroup;

  public emailFormControl = new UntypedFormControl();

  public loading = false;

  public constructor(
    public dialogRef: MatDialogRef<RequestResetPasswordComponent>,
    private authService: AuthService,
    private notificationService: SnackBarNotificationService
  ) {
    this.resetPasswordForm = new UntypedFormGroup({
      email: this.emailFormControl
    });
  }

  public async onRequestResetPassword(): Promise<void> {
    if (this.resetPasswordForm.valid) {
      this.loading = true;

      try {
        await this.authService.firebaseResetPassword(
          this.emailFormControl.value
        );

        this.loading = false;
        this.notificationService.showSuccess(
          'Zkontrolujte e-mail',
          'Pokračujte odkazem v zaslaném e-mailu'
        );
        this.dialogRef.close();
      } catch (e) {
        const error = <FirebaseError>e;
        console.error(error);

        this.notificationService.showError(
          'Tento účet neexistuje',
          'Zkontrolujte zadaný e-mail'
        );

        this.loading = false;
      }
    }
  }

  public faUnlock = faUnlock;
}
