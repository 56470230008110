import { Component, Injector, Input } from '@angular/core';
import { AppBaseComponent } from '../../app-base.component';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent extends AppBaseComponent {
  @Input() public title = 'Zobrazeno';
  @Input() public subtitle = 'Členská sekce';

  public constructor(private injector: Injector) {
    super(injector);
  }
}
