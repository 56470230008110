import { Component, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Sort } from '@angular/material/sort';
import { AppStateService } from '@core/app-state.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarNotificationService } from '@core/snack-bar-notification/snack-bar-notification.service';
import { BreakpointsService } from '@core/breakpoints.service';

@Component({
  template: ''
})
export class AppBaseComponent {
  public onDestroy = new Subject<void>();
  public device: 'phone' | 'tablet' | 'desktop';
  public sortValue: Sort;
  public loading = false;
  public dataLoading = true;
  public dataLength = 0;
  private _injector: Injector;
  public notificationService: SnackBarNotificationService;
  public route: ActivatedRoute;
  public router: Router;
  public titleService: Title;
  public appStateService: AppStateService;
  public componentName = 'AppBaseComponent';
  public location: Location;
  public breakpointsService: BreakpointsService;

  public constructor(injector: Injector) {
    this._injector = injector;
    this.titleService = this._injector.get(Title);
    this.location = this._injector.get(Location);
    this.appStateService = this._injector.get(AppStateService);
    this.route = this._injector.get(ActivatedRoute);
    this.router = this._injector.get(Router);
    this.notificationService = this._injector.get(SnackBarNotificationService);
    this.breakpointsService = this._injector.get(BreakpointsService);

    this.breakpointsService.device$.subscribe((device) => {
      this.device = device;
    });
  }

  public setTitle(value = ''): void {
    this.titleService.setTitle(`${value} - Zobrazeno.com`);
  }

  public goBack(): void {
    this.location.historyGo(-1);
  }

  public get userUid(): string {
    return this.appStateService.userUid;
  }

  public async refreshData(): Promise<boolean> {
    return await this.router.navigateByUrl(this.router.url);
  }

  public get tabIndex(): number {
    const persistValue = sessionStorage.getItem(`${this.componentName}-tab`);

    if (persistValue) {
      return Number(persistValue);
    }

    return 0;
  }

  public set tabIndex(index) {
    sessionStorage.setItem(`${this.componentName}-tab`, String(index));
  }
}
