import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'keepHtml', standalone: true })
export class SanitizeHtmlPipe implements PipeTransform {
  public constructor(private sanitizer: DomSanitizer) {}

  public transform(content: string) {
    if (!content) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
