<button
  class="w-full"
  mat-flat-button
  type="submit"
  [color]="color"
  [disabled]="loading || !form.valid"
>
  <mat-icon *ngIf="loading">
    <mat-spinner></mat-spinner>
  </mat-icon>

  <ng-content />
</button>
