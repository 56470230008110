import { Component, Input } from '@angular/core';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { Article } from '@appTypes/article.interface';

@Component({
  selector: 'app-article-publish-date',
  templateUrl: './publish-date.component.html',
  styleUrl: './publish-date.component.scss'
})
export class PublishDateComponent {
  @Input({ required: true }) public article: Article;
  public faCalendar = faCalendarDays;
}
