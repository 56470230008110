<app-dialog-title title="Darování" [icon]="faCoffee" />

<div mat-dialog-content class="flex flex-col lg:flex-row items-center gap-2">
  <div class="info">
    <div class="flex items-center gap-1">
      <fa-icon [icon]="faDollarSign"></fa-icon>

      <p><strong>Částka:</strong><br />{{ data.price | currency }}</p>
    </div>

    <div class="flex items-center gap-1">
      <fa-icon [icon]="faUniversity"></fa-icon>

      <p><strong>Číslo účtu:</strong><br />2901842626/2010</p>
    </div>

    <div class="flex items-center gap-1">
      <fa-icon [icon]="faComments"></fa-icon>

      <p><strong>Zpráva pro příjemce:</strong><br />Dar pro Odchod z.s.</p>
    </div>

    <div class="flex items-center gap-1">
      <fa-icon [icon]="faFlag"></fa-icon>

      <p><strong>Variabilní symbol:</strong><br />{{ referenceNumber }}</p>
    </div>
  </div>

  <div class="bg-white rounded-[12px] p-1">
    <qrcode
      [elementType]="'svg'"
      [width]="170"
      [qrdata]="
        'SPD*1.0*ACC:CZ0320100000002901842626*AM:' +
        data.price +
        '*CC:CZK*MSG:Dar pro Odchod z.s. *X-VS:' +
        referenceNumber
      "
    />
  </div>
</div>

<div mat-dialog-actions>
  <button
    cdkFocusInitial
    [disabled]="loading"
    class="w-full"
    mat-flat-button
    type="submit"
    color="primary"
    (click)="onCheckPayment()"
  >
    <mat-icon *ngIf="loading">
      <mat-spinner />
    </mat-icon>
    Hotovo, platba odeslána
  </button>
</div>
