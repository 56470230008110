import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat';
import UserCredential = firebase.auth.UserCredential;
import { first, map } from 'rxjs/operators';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  public constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth
  ) {}

  public async firebaseLogin(
    email: string,
    password: string
  ): Promise<UserCredential> {
    await this.angularFireAuth.setPersistence('local');
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  public firebaseResetPassword(email: string): Promise<void> {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  public firebaseSignUp(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  public async logout(): Promise<void> {
    await this.angularFireAuth.signOut();
    localStorage.removeItem('userUid');
    localStorage.removeItem('idToken');
    await this.router.navigate(['/']);
  }

  public getFirebaseUser(): Promise<firebase.User | null> {
    return lastValueFrom(this.angularFireAuth.authState.pipe(first()));
  }

  public async tryRelogin(): Promise<boolean> {
    const firebaseUser = await this.getFirebaseUser();

    if (firebaseUser) {
      const idToken = await firebaseUser?.getIdToken();
      localStorage.setItem('idToken', idToken);
      return true;
    }

    return false;
  }

  public async isLoggedIn(): Promise<boolean> {
    const user = await lastValueFrom(
      this.angularFireAuth.authState.pipe(first())
    );
    return !!user;
  }

  public isLoggedIn$(): Observable<boolean> {
    return this.angularFireAuth.authState.pipe(map((user) => !!user));
  }
}
