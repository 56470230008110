import { Injectable } from '@angular/core';
import { BackendService } from '../backend.service';

@Injectable({ providedIn: 'root' })
export class DonationApiService {
  public constructor(private backendService: BackendService) {}

  public async checkDonation(
    sessionId: string,
    price: number,
    toUserUid: string
  ): Promise<{ status: boolean }> {
    return await this.backendService.post<{ status: boolean }>(
      `/donation/check-donation/${sessionId}/${this.backendService.userUid}`,
      { price, toUserUid }
    );
  }

  public async checkDonationTransfer(
    referenceNumber: number,
    price: number,
    toUserUid: string
  ) {
    return await this.backendService.post<{ status: boolean }>(
      `/donation/check-transfer-donation/${referenceNumber}/${this.backendService.userUid}`,
      { price, toUserUid }
    );
  }
}
