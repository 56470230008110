import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthGuard } from '@core/authentication/auth.guard';
import { AuthService } from '@core/authentication/auth.service';
import { AuthInterceptor } from '@core/authentication/auth.interceptor';
import { AppStateService } from './app-state.service';
import { SnackBarNotificationService } from './snack-bar-notification/snack-bar-notification.service';
import { SnackBarNotificationComponent } from './snack-bar-notification/snack-bar-notification.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

function throwIfAlreadyLoaded(parentModule: unknown, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [
    // authentication
    AuthGuard,
    AuthService,

    //other
    AppStateService,
    SnackBarNotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  declarations: [SnackBarNotificationComponent],
  exports: []
})
export class CoreModule {
  public constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
