import { Component, Input } from '@angular/core';
import { Article } from '@appTypes/article.interface';

@Component({
  selector: 'app-article-info',
  templateUrl: './article-info.component.html',
  styleUrls: ['./article-info.component.scss']
})
export class ArticleInfoComponent {
  @Input({ required: true }) public article: Article;
}
