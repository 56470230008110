import { Component, Input, OnInit } from '@angular/core';

const posterVertical =
  'https://firebasestorage.googleapis.com/v0/b/odchod---club.appspot.com/o/poster-video.png?alt=media&token=919857a9-8888-4491-9bc1-18fe45295557';
const posterHorizontal =
  'https://firebasestorage.googleapis.com/v0/b/odchod---club.appspot.com/o/poster-video-horizontal.png?alt=media&token=e8409c9a-dc50-45df-9827-b31547768100';
const videoHorizontal =
  'https://player.vimeo.com/progressive_redirect/playback/907809323/rendition/720p/file.mp4?loc=external&signature=2792ac656221f6c4bb87bed450d05de809834fb88de1698ab5e3b746d2a20cee';
const videoVertical =
  'https://player.vimeo.com/progressive_redirect/playback/889753779/rendition/1080p/file.mp4?loc=external&signature=7d2973b300e07f7dd3af1ffe42fe560bda631881ead4f67e5490b4912a0c7c62';

@Component({
  selector: 'app-shared-promo-video',
  templateUrl: './promo-video.component.html',
  styleUrl: './promo-video.component.scss'
})
export class PromoVideoComponent implements OnInit {
  @Input() public horizontal = false;
  public poster: string;
  public videoUrl: string;
  @Input() public height = '650px';
  @Input() public iconSize = 85;

  public ngOnInit(): void {
    if (this.horizontal) {
      this.poster = posterHorizontal;
      this.videoUrl = videoHorizontal;
    } else {
      this.poster = posterVertical;
      this.videoUrl = videoVertical;
    }
  }
}
