import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '@shared/shared.module';
import { CloseButtonComponent } from '@shared/components/close-button/close-button.component';
import { DialogTitleComponent } from '@shared/components/dialog-title/dialog-title.component';
import { MakeContactComponent } from './make-contact/make-contact.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SubmitButtonComponent } from '@shared/components/submit-button/submit-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    SharedModule,
    CloseButtonComponent,
    DialogTitleComponent,
    MatFormFieldModule,
    MatInputModule,
    SubmitButtonComponent
  ],
  declarations: [RequestResetPasswordComponent, MakeContactComponent]
})
export class DialogsModule {}
