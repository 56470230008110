<app-dialog-title title="Zapomenuté heslo" [icon]="faUnlock" />

<form [formGroup]="resetPasswordForm" (ngSubmit)="onRequestResetPassword()">
  <div mat-dialog-content>
    <app-text-input
      id="email"
      type="email"
      label="E-mail"
      autocomplete="username"
      [required]="true"
      [formControl]="emailFormControl"
    />
  </div>
  <div mat-dialog-actions>
    <app-submit-button
      class="w-full"
      [loading]="loading"
      [form]="resetPasswordForm"
    >
      Resetovat heslo
    </app-submit-button>
  </div>
</form>
