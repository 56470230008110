<app-dialog-title title="Kontakt" [icon]="faEnvelope" />

<form [formGroup]="contactForm" (ngSubmit)="onSend()">
  <div mat-dialog-content>
    <app-text-input
      id="email"
      type="email"
      label="Váš e-mail"
      [required]="true"
      [formControl]="emailFormControl"
    />

    <textarea
      class="bg-white mt-2 w-full text-area"
      placeholder="Vaše zpráva"
      matInput
      [formControl]="bodyFormControl"
      cdkTextareaAutosize
      cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="7"
    ></textarea>
  </div>
  <div mat-dialog-actions>
    <app-submit-button class="w-full" [loading]="loading" [form]="contactForm">
      Odeslat
    </app-submit-button>
  </div>
</form>
