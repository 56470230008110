import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialogComponent } from '@shared/components/cropper-tool/image-cropper-dialog/image-cropper-dialog.component';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cropper-tool',
  templateUrl: './cropper-tool.component.html',
  styleUrls: ['./cropper-tool.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CropperToolComponent
    }
  ]
})
export class CropperToolComponent implements ControlValueAccessor {
  @Input() public isAvatarMode = false;
  @Input() public aspectRatio = 1;
  @Input() public placeholderImage = '/assets/images/avatar-placeholder.png';
  public file = '';

  public constructor(public dialog: MatDialog) {}

  public writeValue(_file: string): void {
    this.file = _file;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange = (fileUrl: string) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched = () => {};

  private disabled = false;

  public onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      this.resetInput();

      this.openCropperToolEditor(_file).subscribe((result) => {
        if (result) {
          this.file = result;
          this.onChange(this.file);
        }
      });
    }
  }

  private openCropperToolEditor(image: string): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      maxWidth: '85vw',
      maxHeight: '85vh',
      data: { image, parent: this }
    });

    return dialogRef.afterClosed();
  }

  private resetInput() {
    const input = document.getElementById(
      'cropper-tool-input-file'
    ) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  public faCameraRetro = faCameraRetro;
}
