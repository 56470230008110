import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { nanoid } from 'nanoid';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ]
})
export class TextInputComponent implements OnInit, ControlValueAccessor {
  @Input() public id = nanoid(10);
  @Input() public label: string;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public class = 'w-full';
  @Input() public type = 'text';
  @Input() public requiredErrorMessage = 'Povinná položka';
  @Input() public minlengthErrorMessage = 'Minimální délka';
  @Input() public placeholder = '';
  @Input() public minlength: number;
  @Input() public autocomplete: string;
  @Input() public formControl: FormControl;
  @Input() public value: string | number;
  @Input() public min: number;
  @Input() public step: number;

  public hidePassword = true;
  public originalType: string;

  public ngOnInit(): void {
    if (!this.formControl) {
      this.formControl = new UntypedFormControl();
    }

    if (this.value !== undefined) {
      this.formControl.setValue(this.value);
    }

    if (this.disabled) {
      this.formControl.disable();
    }

    this.originalType = this.type;
    if (this.type === 'email' && !this.placeholder) {
      this.placeholder = 'jan.novak@email.cz';
    }

    const validators = [];

    if (this.type === 'email') {
      validators.push(Validators.email);
    }

    if (this.type === 'number' && this.min !== undefined) {
      validators.push(Validators.min(this.min));
    }

    if (this.requiredErrorMessage && this.required) {
      validators.push(Validators.required);
    }

    if (this.minlengthErrorMessage && this.minlength) {
      validators.push(Validators.minLength(this.minlength));
    }

    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: unknown = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouch: unknown = () => {};

  public registerOnChange(fn: unknown): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: unknown): void {
    this.onTouch = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
  }
}
