import { Component, Injector, Input, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';
import { Article } from '@appTypes/article.interface';
import { LikeApiService } from '@core/api/like-api.service';
import { AppBaseComponent } from '../../../../app-base.component';
import { Event } from '@appTypes/event.interface';
import { MatDialog } from '@angular/material/dialog';
import { MakeDonationDialogComponent } from '@shared/dialogs/make-donation-dialog/make-donation-dialog.component';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons';

const COPY_SUCCESS_TIMEOUT = 4000;
@Component({
  selector: 'app-article-actions',
  templateUrl: './article-actions.component.html',
  styleUrl: './article-actions.component.scss'
})
export class ArticleActionsComponent extends AppBaseComponent {
  @Input({ required: true }) public article: Article;
  @ViewChild('tooltip') public tooltip!: MatTooltip;
  public copied = false;
  @Input() public disableLike = false;
  @Input() public disableDonation = false;
  @Input() public event?: Event;

  public constructor(
    private clipboard: Clipboard,
    private injector: Injector,
    private likeApiService: LikeApiService,
    private dialog: MatDialog
  ) {
    super(injector);
  }
  public get tooltipContent(): string {
    return this.copied
      ? 'Odkaz zkopírován do schránky'
      : 'Zkopírovat odkaz na článek';
  }

  public copyArticleLink(): void {
    if (!this.copied) {
      this.clipboard.copy(`${environment.baseUrl}/clanek/${this.article.id}`);
      this.copied = true;

      setTimeout(() => {
        this.tooltip.show();
      });

      setTimeout(() => {
        this.copied = false;
      }, COPY_SUCCESS_TIMEOUT);
    }
  }

  public async makeLike(): Promise<void> {
    await this.likeApiService.makeLike({
      objectId: this.article.eventId,
      mode: 'ARTICLE'
    });
    await this.refreshData();
  }

  public openMakeDonationDialog(): void {
    this.dialog.open(MakeDonationDialogComponent, {
      backdropClass: 'shadowDialog',
      data: { authorUid: this.article.userUid }
    });
  }

  public faThumbsUpRegular = faThumbsUpRegular;
  public faThumbsUpSolid = faThumbsUpSolid;
  public faDollar = faDollar;
}
