<div class="article-actions flex items-center gap-1">
  <div class="article-thumbup" *ngIf="event && !disableLike">
    <button
      [matBadge]="event.likes | countLikes"
      mat-icon-button
      class="feed-thumbup__button"
      (click)="makeLike()"
    >
      <fa-icon
        [icon]="event?.likes?.[userUid] === true ? faThumbsUpSolid : faThumbsUpRegular"
        [class.text-primary]="event?.likes?.[userUid] === true"
      />
    </button>
  </div>

  <div class="article-share">
    <button
      #tooltip="matTooltip"
      (click)="copyArticleLink()"
      [matTooltip]="tooltipContent"
      color="primary"
      mat-flat-button
    >
      Sdílet
    </button>
  </div>

  <button
    matTooltip="Darovat autorovi"
    mat-fab
    class="shadow-none"
    color="accent"
    *ngIf="!disableDonation"
    (click)="openMakeDonationDialog()"
  >
    <fa-icon [icon]="faDollar"></fa-icon>
  </button>
</div>
