import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { NotFoundComponent } from './404/404.component';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AppBaseComponent } from './app-base.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogsModule } from './dialogs/dialogs.module';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [AppComponent, NotFoundComponent, AppBaseComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,

    CoreModule,
    DialogsModule,

    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxGoogleAnalyticsModule.forRoot(environment.gaCode),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'cs-CZ' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CZK' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
