import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-submit-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
  templateUrl: './submit-button.component.html',
  styleUrl: './submit-button.component.scss'
})
export class SubmitButtonComponent {
  @Input({ required: true }) public form: UntypedFormGroup;
  @Input({ required: true }) public loading = false;
  @Input() public color = 'primary';
}
