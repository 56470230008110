import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { SnackBarNotificationService } from '@core/snack-bar-notification/snack-bar-notification.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactApiService } from '@core/api/contact-api.service';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dialogs-make-contact',
  templateUrl: './make-contact.component.html',
  styleUrls: ['./make-contact.component.scss']
})
export class MakeContactComponent {
  public contactForm: UntypedFormGroup;

  public emailFormControl = new UntypedFormControl();
  public bodyFormControl = new UntypedFormControl('', Validators.required);

  public loading = false;

  public constructor(
    public dialogRef: MatDialogRef<MakeContactComponent>,
    private notificationService: SnackBarNotificationService,
    private contactApiService: ContactApiService
  ) {
    this.contactForm = new UntypedFormGroup({
      email: this.emailFormControl,
      body: this.bodyFormControl
    });
  }

  public async onSend(): Promise<void> {
    if (this.contactForm.valid) {
      this.loading = true;

      try {
        await this.contactApiService.sendMessage(this.contactForm.value);

        this.notificationService.showSuccess(
          'Děkujeme!',
          'Budeme se snažit Vám odpovědět co nejdříve.'
        );
        this.dialogRef.close();
      } catch (e) {
        this.notificationService.showError(
          'Chyba při odesílání',
          'Zkuste to prosím později'
        );
      } finally {
        this.loading = false;
      }
    }
  }

  protected readonly faEnvelope = faEnvelope;
}
