<ng-container *ngIf="formControl">
  <div [class]="class">
    <label *ngIf="label" class="font-semibold mb-1" [for]="id">{{
      label
    }}</label>

    <div class="input-wrapper">
      <input
        class="border border-black w-full"
        [attr.required]="required"
        [readonly]="readonly"
        [id]="id"
        [name]="id ?? null"
        [min]="min ?? null"
        [step]="step ?? null"
        [type]="
          originalType === 'password'
            ? hidePassword
              ? 'password'
              : 'text'
            : type
        "
        [formControl]="formControl"
        [value]="value"
        [placeholder]="placeholder"
        [attr.autocomplete]="autocomplete ?? null"
      />
    </div>

    <ng-container *ngIf="formControl.value || type == 'number'">
      <mat-error
        *ngIf="
          formControl.hasError('required') &&
          !formControl.hasError('minlength') &&
          !formControl.hasError('email')
        "
      >
        {{ requiredErrorMessage }}
      </mat-error>
      <mat-error
        *ngIf="
          formControl.hasError('minlength') &&
          !formControl.hasError('required') &&
          !formControl.hasError('email')
        "
      >
        {{ minlengthErrorMessage }}
      </mat-error>
      <mat-error
        *ngIf="
          formControl.hasError('email') &&
          !formControl.hasError('minlength') &&
          !formControl.hasError('required')
        "
      >
        E-mail nemá správný formát
      </mat-error>
      <mat-error *ngIf="formControl.hasError('min')">
        Hodnota musí být větší nebo rovna {{ min }}
      </mat-error>
    </ng-container>
  </div>

  <!--<mat-form-field>
    <ng-container matSuffix>
      <ng-content select="[suffix]"></ng-content>
    </ng-container>

    <mat-icon
      *ngIf="originalType === 'password'"
      class="cursor-pointer"
      matSuffix
      (click)="hidePassword = !hidePassword"
    >
      {{ hidePassword ? 'visibility_off' : 'visibility' }}
    </mat-icon>
  </mat-form-field>-->
</ng-container>
