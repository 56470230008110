<div class="flex flex-col lg:flex-row items-center gap-1">
  <app-shared-profile-image
    class="author-list__user-img"
    width="64px"
    height="64px"
    [profileImageUrl]="article.author.profileImageUrl"
    *ngIf="article.author?.profileImageUrl"
  />
  <div class="author">
    <p class="font-bold m-0">{{ article.author?.name }}</p>
    <p class="underline text-center lg:text-left m-0">Autor článku</p>
  </div>
</div>
