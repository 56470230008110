<div class="relative">
  <video
    [poster]="poster"
    class="rounded-[12px] max-sm:h-auto"
    [style.height]="height"
    #videoRef
  >
    <source [src]="videoUrl" type="video/mp4" />
  </video>

  <button
    id="play-button"
    #playButton
    (click)="
      videoRef.controls = true; videoRef.play(); playButton.hidden = true
    "
    [style.left]="'calc(50% - ' + iconSize / 2 + 'px)'"
    [style.top]="'calc(50% - ' + iconSize / 2 + 'px)'"
  >
    <img
      src="/assets/icons/play-icon.png"
      [style.height]="iconSize + 'px'"
      [style.width]="iconSize + 'px'"
    />
  </button>
</div>
