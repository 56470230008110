import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from '@core/app-state.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(200, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AppComponent implements OnDestroy, OnInit {
  public appLoading = new BehaviorSubject(<undefined | boolean>undefined);
  private onDestroy = new Subject<void>();

  public constructor(
    private router: Router,
    private appStateService: AppStateService
  ) {
    interval(1000)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.appLoading.next(this.appStateService.isAppLoading.value);
      });

    this.router.events.pipe(takeUntil(this.onDestroy)).subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.appStateService.isAppLoading.next(true);
      }
      if (
        ev instanceof NavigationEnd ||
        ev instanceof NavigationCancel ||
        ev instanceof NavigationError
      ) {
        this.appStateService.isAppLoading.next(false);
      }
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public ngOnInit(): void {
    registerLocaleData(localeCs, 'cs');
  }
}
