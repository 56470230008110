import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Cropper from 'cropperjs';
import { CropperToolComponent } from '@shared/components/cropper-tool/cropper-tool.component';

interface ImageCropperDialogComponentData {
  image: string;
  parent: CropperToolComponent;
}

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageCropperDialogComponent implements OnInit, AfterViewInit {
  public sanitizedUrl!: SafeUrl;
  private cropper!: Cropper;

  public constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperDialogComponentData,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(this.data.image);
  }

  public ngAfterViewInit() {
    this.initCropper();
  }

  private initCropper() {
    const image = document.getElementById('image') as HTMLImageElement;
    this.cropper = new Cropper(image, {
      aspectRatio: this.data.parent.aspectRatio,
      viewMode: 1,
      guides: false,
      responsive: true,
      dragMode: 'move'
    });
  }

  private getRoundedCanvas(sourceCanvas: any) {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
    return canvas;
  }

  public crop() {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    let dataUrl: string;
    if (this.data.parent.isAvatarMode) {
      const roundedCanvas = this.getRoundedCanvas(croppedCanvas);
      dataUrl = roundedCanvas.toDataURL();
    } else {
      dataUrl = croppedCanvas.toDataURL();
    }

    const img = document.createElement('img');

    if (img) {
      this.dialogRef.close(dataUrl);
    } else {
      return this.dialogRef.close(null);
    }
  }

  public reset() {
    this.cropper.clear();
    this.cropper.crop();
  }
}
